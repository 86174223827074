<template>
  <div class="home-container">

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-notice-bar v-if="notice_text != ''" background="#f0dadc" color="black" left-icon="volume-o" :text="notice_text" />

      <van-tabs swipe-threshold='3' @click='selects' :active="active" shrink>

        <van-tab title="商超代金券">
          <van-tabs @click='selectstatus' class='navtab' title-active-color='black' v-model="actives" animated>
            <van-tab v-for="(item,ind) in navlist" :key="ind" :title="item.name">
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" @load="loadings">
                <div v-for="(i,ind) in list" :key="ind" class="qy-content qy-fen">
                  <div class="qy-content-img">
                    <img :src="i.logo">
                  </div>
                  <div class="qy-content-info">
                    <span>{{ i.storeName }}</span>
                    <span style="font-size: .3rem;
    background: #ffe9e9;
    border-radius: 0.48rem;
    text-align: center;
    width: 2.5rem;
    color: #e60027;">
                      满{{ i.transactionMinimum }}可用 </span>
                    <span :style="{ color: (status == 0 ? '#CB4643' : '#999999') }">
                      {{ i.effectiveTimeEnd }}
                    </span>
                  </div>
                  <div v-show="i.status == 0 && status != 2" class="qy-button  qy-button-fen" @click="activated(i.id)">
                    <span>激活</span>
                  </div>

                  <div style="position: absolute; right: 0.1rem; top:0.8rem; font-size: 0.5rem;">
                    <wx-open-launch-weapp v-if="i.status == '1' && i.enterpriseType == '2'" username="gh_6213b326c9f6">
                      <script type="text/wxtag-template">
                        <style>.btn {  background-color: rgb(255, 255, 255);
                                                                                                                                              padding: 0.25rem 0.5rem;
                                                                                                                                              text-align: center;
                                                                                                                                              border-radius: 1rem;border: none;}</style>
                                                                                                                                               <button class="btn">去使用</button>
                                                                                                               
                                                  </script>
                    </wx-open-launch-weapp>
                  </div>
                  <div v-show="i.status != 0" style='position: absolute;
          right: 0.3rem;
          top: 0.3rem;'>
                    <img v-show="i.status == 0" style="width: 2rem;" src="../../assets/img2/wjh.png">
                    <img v-show="i.status == 1 && i.enterpriseType != '2'" style="width: 2rem;" src="../../assets/img2/yjh.png">
                    <img v-show="i.status == 2" style="width: 2rem;" src="../../assets/img2/ysy.png">
                    <!-- <span v-show="i.status == 0">未激活</span> -->
                    <!-- <span v-show="i.status == 1">已激活</span> -->
                    <!-- <span v-show="i.status == 2">已使用</span> -->
                  </div>
                </div>
              </van-list>
            </van-tab>
          </van-tabs>
        </van-tab>

        <van-tab title="充值券" v-if="shows == 15">
          <van-tabs @click='selectstatus' class='navtab' title-active-color='black' v-model="activess" animated>
            <van-tab v-for="(item,ind) in navlist" :key="ind" :title="item.name">
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" @load="loadings">
                <div v-for="(i,ind) in list" :key="ind" class="qy-content qy-fen">
                  <div class="qy-content-img">
                    <img :src="i.logo">
                  </div>
                  <div class="qy-content-info">
                    <span>{{ i.storeName }}</span>
                    <span style="    font-size: .3rem;
          background: #ffe9e9;
          border-radius: 0.48rem;
          text-align: center;
          width: 2.5rem;
          color: #e60027;">
                      满{{ i.transactionMinimum }}可用 </span>
                    <span :style="{ color: (status == 0 ? '#CB4643' : '#999999') }">
                      {{ i.effectiveTimeEnd }}
                    </span>
                  </div>
                  <div v-show="i.status == 0" class="qy-button  qy-button-fen" @click="activated(i.id)">
                    <span>激活</span>
                  </div>

                  <div style="position: absolute; right: 0.1rem; top:0.8rem; font-size: 0.5rem;">
                    <wx-open-launch-weapp v-if="i.status >= '1' && i.enterpriseType == '2'" username="gh_6213b326c9f6">
                      <script type="text/wxtag-template">
                        <style>.btn {  background-color: rgb(255, 255, 255);
                                                            </script>
                    </wx-open-launch-weapp>
                  </div>
                  <div v-show="i.status != 0" style='position: absolute;
                  right: -0.1rem;
    top: -0.6rem;'>
                    <img v-show="i.status == 0" style="width: 1.5rem;" src="../../assets/img2/wjh.png">
                    <img v-show="i.status == 1" style="width:1.5rem;" src="../../assets/img2/yjh.png">
                    <img v-show="i.status == 2" style="width: 1.5rem;" src="../../assets/img2/ysy.png">
                  </div>
                </div>
              </van-list>
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>

  </div>
</template>

<script>
import {
  getUserCouponList,
  wtdecrypt,
  getToken,
  getuserInfo,
  getOauth2AccessToken,
  activatedCoupon,
  getNotice,
  getWxConfig,
  getUserArea,
} from "@/api/user";
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import { getItem } from "@/utils/storage";
import wxApi from "@/api/wxapi";

import { isWXBrowser, getWechatCode } from "@/api/getcode";
export default {
  name: "home",

  props: {},
  data() {
    return {
      shows: null, //充值券展示
      notice_text: "",
      openid: "",
      active: 0, //被激活的列表
      actives: 0, //被激活的列表
      activess: 0, //被激活的列表
      channels: [], //频道列表
      isChannelEditShow: false, //频道编辑层
      navlist: [
        {
          name: "未使用",
        },
        {
          name: "已使用",
        },
        {
          name: "已失效",
        },
      ],
      enterpriseType: "0",

      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      from: 0,
      status: 0,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {},
  async created() {
    if (isWXBrowser) {
      var code = getWechatCode();
      if (!code) {
        return;
      }
      await this.getopenid(code);
      let urlinfo = this.geturl();
      let registerUrl = window.location.href;
      let res = await getWxConfig({
        url: registerUrl.split("#")[0],
      });
      wxApi.wxRegister(res.data);
      if (urlinfo == "") {
        // setTimeout(()=>{        this.loadings()},5000 )
        // this.loadings()
      } else {
        this.wtdecryptapi(urlinfo);
      }
    } else {
      // this.loadings()
    }
    // this.loadings()
    // let registerUrl = window.location.href
    // let res = await getWxConfig({
    //   url: registerUrl.split('#')[0]
    // })
    // wxApi.wxRegister(res.data)
    // this.loadChannels()
  },
  async mounted() {
    // this.onLoad()
    this.record({
      type: 8,
      resourceId: "",
    });
    var datas = await getNotice({ noticeType: "2" });
    if (datas.data.code == "0") {
      this.notice_text = datas.data.data;
    }
  },
  methods: {
    wxRegCallback() { },
    selects(val) {
      this.list = [];
      if (val == 0) {
        this.status = 0;
        this.actives = 0;
        this.from = 1;
        this.enterpriseType = "0";
        this.onLoad();
      } else {
        this.enterpriseType = "2";
        this.status = 0;
        this.activess = 0;
        this.from = 1;
        this.onLoad();
      }
    },
    async userinfos() {
      // this.finished=true
    },
    async getopenid(code) {
      const { data } = await getOauth2AccessToken({
        code: code,
      });

      this.openid = data.data;
      console.log("123213:" + data.data);
    },
    onRefresh() {
      setTimeout(() => {
        this.refreshing = false;
      }, 1000);
      this.from = 0;
      this.list = [];
      this.loadings();
    },
    geturl() {
      try {
        var url = window.location.href; //获取当前url
        var dz_url = url.split("#")[1]; //获取#/之后的字符串
        // var dz_url = url.split('#')[0];                //获取#/之前的字符串
        var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
        console.log(cs);
        var cs_arr = cs.split("&"); //参数字符串分割为数组
        var cs = {};
        for (var i = 0; i < cs_arr.length; i++) {
          //遍历数组，拿到json对象
          cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
        }
        return decodeURIComponent(cs.userInfo);
      } catch (e) {
        return "";
        //TODO handle the exception
      }
    },
    async wtdecryptapi(userInfo) {
      console.log("sadasd:" + this.openid);
      // userInfo = 'R+JSr79J/UPcCR18iYSidU9qOLMJZG7F19erYXyOWEUYuqsDdtXuZKGBPnZ0sQeakGakH7u5y8Go7V+pOx2J3g=='
      let parma = {
        userInfo: userInfo,
        openid: this.openid,
        // "openid": 'oGWjZs8LKqM7MJHpS-aZLryaTwMI',
      };
      const { data } = await wtdecrypt(parma);

      if (data.code == "500100") {
        Dialog.alert({
          message: "当前页面已失效，请重新进入当前页面",
        }).then(() => {
          // on close
        });

        return;
      }

      let param = {
        mobile: data.data,
      };

      this.get_token(data.data);
    },

    async get_token(mobile) {
      let param = {
        mobile: mobile,
      };

      const { data } = await getToken(param);

      if (data.code == 0) {
        var dats = {
          token: data.data,
        };
        this.$store.dispatch("AddUser", dats).then(() => {
          if (localStorage.getItem("userArea") != null) {
            let res = JSON.parse(localStorage.getItem("userArea"));
            this.shows = res.id;
          }
        });
        // this.$store.commit('setUser', dats)
      }

      var datas = await getuserInfo();

      console.log(datas.data.data.userType);
      this.userType = datas.data.data.userType;

      if (this.userType != "1") {
        Dialog.alert({
          title: "",
          message: "请到中国 移动APP开通生活权益会员！",
        });
        return;
      }
      this.loadings();
    },
    async activated(id) {
      Toast.loading({
        message: "激活中请稍等...",
        forbidClick: true,
      });

      let parma = {
        // userId:12,
        //   "userMobile":"15028155600",
        busiUserCouponId: id,
      };
      const { data } = await activatedCoupon(parma);

      Toast.clear();

      Dialog.alert({
        title: "",
        message: data.msg,
      }).then(() => {
        // on close
        this.list = [];
        this.from = 0;
        this.loadings();
        // location.reload()
        // this.$router.go(0)
      });
    },
    selectstatus(name, title) {
      this.status = name;
      this.list = [];
      this.from = 1;
      this.onLoad();
    },
    async loadings() {
      this.loading = true;
      this.from = this.from + 1;

      await this.onLoad();
      this.loading = false;
    },
    async onLoad() {
      console.log(this.from);
      let parma = {
        // userId:12,
        //   "userMobile":"15028155600",
        from: this.from,
        enterpriseType: this.enterpriseType,
        size: 10,
        status: this.status,
      };
      const { data } = await getUserCouponList(parma);
      if (JSON.stringify(data.data) === "[]") {
        this.loading = true;
        this.finished = true;
      }

      if (JSON.stringify(this.list) === "[]") {
        this.list = data.data;
      } else {
        data.data.forEach((i, k) => {
          this.list.push(i);
        });
      }
    },
  },
};
</script>

<style lang="less">
.home-container {
  .van-list {
    // height: calc(100vh - 4.2rem);
    // overflow: auto;
  }

  .navtab {
    .van-tab {
      font-size: 0.38rem;
    }

    .van-tab--active {
      font-weight: 700;
    }
  }

  .qy-content {
    position: relative;
    width: 100%;
    height: 2.8rem;

    background-size: 100% 100%;
    margin: 0.2rem 0;
    display: flex;
  }

  .qy-fen {
    background-image: url(../../assets/img/quanyifen.png);
  }

  .qy-hui {
    background-image: url(../../assets/img/qianyihui.png);
  }

  .qy-content-img {
    img {
      width: 1.8rem;
      height: 2.2rem;
      display: block;
      padding: 0.3rem 0.35rem;
    }
  }

  .qy-content-info {
    padding-top: 0.2rem;

    span {
      display: block;

      line-height: 0.8rem;
      margin-left: 0.5rem;

      &:nth-child(1) {
        font-size: 0.34rem;
        font-weight: 700;
      }

      &:nth-child(3) {
        font-size: 0.32rem;
        color: #cb4643;
      }
    }
  }

  .qy-button {
    width: 1rem;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    right: 0.05rem;

    span {
      font-size: 0.38rem;
      display: block;
      width: 25%;
      color: #ffffff;
      text-align: center;
      margin: 0.8rem auto;
    }
  }

  .qy-button-fen {
    background-image: url(../../assets/img/quanyijihuo.png);
  }

  .qy-button-hui {
    background-image: url(../../assets/img/quanyiweijihuo.png);

    span {
      color: #999999 !important;
    }
  }
}
</style>
